import React, { useEffect, useRef } from 'react';

const RealWorkLabsPlugin = () => {
    const scriptRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://app.realworklabs.com/static/plugin/loader.js?v=' + new Date().getTime();
        scriptRef.current = script;

        window.addEventListener('rwlPluginReady', () => {
            window.rwlPlugin.init('https://app.realworklabs.com', 'aY9ZN4xJrDtlRhul');
        }, false);

        document.body.appendChild(script);

        return () => {
            if (scriptRef.current) {
                document.body.removeChild(scriptRef.current);
            }
        };
    }, []);

    return (
        <div id="rwl-output"></div>
    );
};

export default RealWorkLabsPlugin;
