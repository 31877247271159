import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import BlockCliente from '../components/block/BlockCliente'

function Projects() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Recent Projects">
      <div className="md:max-w-full w-full ">
        <TransparentHeader
          headertitle="Recent Projects"
          Subheader="Recent Projects"
          bgimg={`${rpdata?.stock?.[9]}`}
        />

        <div className="md:px-8 px-3 py-14">
            <BlockCliente />
        </div>

      </div>
    </BaseLayout>
  );
}

export default Projects;
